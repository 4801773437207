import { Link } from 'gatsby'

import React, { ReactElement, useEffect } from 'react';
import { Layout } from '../components/Layout/Layout';
import { Intro } from '../modules/Intro/Intro';

const PageAffiliate = ({location}): ReactElement => (
  useEffect(()=>{
    
    setTimeout(()=>{
      const patharr = location.pathname.split('/')
      let swcode = patharr[1]
      if(swcode == "get")
        swcode = patharr[2]
      
      if((typeof swcode !== 'undefined')){
        localStorage.setItem( "swcode", (swcode).replace(/\//g, '') )
        const deal_qstr =  window.location.href.split('?')[1];
        window.location.replace((typeof deal_qstr !== 'undefined') ? '/special' + '?' + deal_qstr : '/special')
      }else{
        window.location.replace('/notfound')
      }
    }, 500)


  }, []),

  <Layout>
    
 
        <Intro
      title={
        <> </>
      }
      subTitle={<></>}
      
    />
  </Layout>
)

export default PageAffiliate
