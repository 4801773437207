import React, { ReactElement, useEffect } from "react";
import { css } from "@emotion/react";
import { Layout } from "../components/Layout/Layout";
import { DealIntroNewV2Sale } from "../modules/DealIntroNew/DealIntroNewV2Sale";
import { SecretSauce } from "../modules/SecretSauce/SecretSauce";
import { VisualBenefits } from "../modules/VisualBenefits/VisualBenefits";
import { Introduction } from "../modules/Introduction/Introduction";
import { EyeCatchingTemplates } from "../modules/EyeCatchingTemplates/EyeCatchingTemplates";
import { OptionOne } from "../modules/EyeCatchingTemplates/OptionOne";
import { OptionTwo } from "../modules/EyeCatchingTemplates/OptionTwo";
import { SketchEffect } from "../modules/SketchEffect/SketchEffect";
import { GrabAttention } from "../modules/GrabAttention/GrabAttention";
import { FantasticReasons } from "../modules/FantasticReasons/FantasticReasons";
import { CAROUSEL_DATA, CAROUSEL_DATA1 } from "../data/carousel";
import { Carousel } from "../modules/Carousel/Carousel";
import { Features } from "../modules/Features/Features";
import { FEATURES_DATA } from "../data/features";
import { TestimonialsMore } from "../modules/Testimonials/TestimonialsMore";
import { TESTIMONIALS_DATA } from "../data/testimonials";
import { ZizzlingBonuses } from "../modules/ZizzlingBonuses/ZizzlingBonuses";
import { SketchWowCostsSection } from "../modules/SketchWowCostsSection/SketchWowCostsSection";
import { SketchWowVersionsSection } from "../modules/SketchWowVersionsSection/SketchWowVersionsSection";
import { DealPricing } from "../modules/DealPricing/DealPricing";
import { Intro } from "../modules/Intro/Intro";
import { ReviewBanner } from "../modules/ReviewBanner/ReviewBanner";
import { ExitIntentPopup } from "../modules/ExitIntentPopup/ExitIntentPopup";
import { LifetimePlansPricing } from '../modules/LifetimePlans/LifetimePlansPricing';
import { CWScripts } from "../modules/CWScripts/CWScripts";
//import { ScaleScripts } from "../modules/ScaleScripts/ScaleScripts";
import { CWCapterraReviews } from "../modules/CWScripts/CWCapterraReviews";
import { CWYoutubeShortVideos } from "../modules/CWScripts/CWYoutubeShortVideos";

const YTDealPage = React.memo(
  (): ReactElement => (


    <Layout>
      <DealIntroNewV2Sale  />
      <ReviewBanner  /> 
      <SecretSauce grayBottom={true}/>
      <Carousel
        carouselType="customer"
        data={CAROUSEL_DATA1}
        title="What are people making using SketchWow? Take a look..." 
        sketch="customer"
      />
      <VisualBenefits />
      <CWYoutubeShortVideos/>
      <Introduction />
       
      <Carousel
        data={CAROUSEL_DATA}
        title="Here's a taste of the awesome templates waiting for you inside SketchWow..."
        showWowIcon={true}
      />
      <CWCapterraReviews/>
      <EyeCatchingTemplates />
      <OptionOne />
      <OptionTwo />
      <SketchEffect />
      <GrabAttention />
      <FantasticReasons />
      <Features
        data={FEATURES_DATA}
        title="Start creating amazing sketches that stand out…"
      />
      <TestimonialsMore
        data={TESTIMONIALS_DATA}
        title="You'll Love SketchWow Too, Just Like Our Customers Do..."
      />
      <ZizzlingBonuses />
      <SketchWowCostsSection />
      <SketchWowVersionsSection FBExclusive={false} />
      <DealPricing withTitle={false} />
      <Intro
        styles={css`
          margin-top: -120px;
        `}
        title={
          <>
           Ready To Ditch The Dull Diagrams & Flowcharts?
          </>
        }
        withSignUp={true}
        withFooter={true}
      />

      {/* <DealPricing withTitle={true} />
      <LifetimePlansPricing/> */}
        
      <ExitIntentPopup />
     <CWScripts/>
     {/* <ScaleScripts/> */}
     
    </Layout>
  )
);


export default YTDealPage;
