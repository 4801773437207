import React, { ReactElement, useEffect } from 'react';
import { Layout } from '../components/Layout/Layout';
import { Intro } from '../modules/Intro/Intro';

const SendReviewPage = (): ReactElement => (
    useEffect(()=>{
        setTimeout(function(){
            window.location.replace('https://www.google.com/search?q=customer+reviews+sketchwow+site%3Atrustpilot.com')
        }, 100)
        

        }, []),

        <Layout>
        <Intro
      title={
        <> </>
      }
      subTitle={<></>}
      
    />
  </Layout>
);

export default SendReviewPage;
