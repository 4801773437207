import React, { ReactElement, useRef, useEffect, useState } from 'react'; 
import "./style_yt_shortvids.css"
import { useLocation } from '@reach/router';
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';


export const CWYoutubeShortVideos = (): ReactElement => {
 
  const location = useLocation();
 
  useEffect(()=>{
    
    const swiperContainers = document.querySelectorAll(".cw-sw17-swip-container");
    swiperContainers.forEach((container) => {
        const parentSection = container.closest(".cw-sw17-swip-section-main");
        if (!parentSection) {
            console.error("Parent section not found for Swiper:", container);
            return;
        }

        new Swiper(container, {
            slidesPerView: 1,
            spaceBetween: 10,
            centeredSlides: true,
            initialSlide: 1,
            loop: false,
            navigation: {
                nextEl: parentSection.querySelector(".swiper-button-next"),
                prevEl: parentSection.querySelector(".swiper-button-prev"),
            },
            breakpoints: {
                1024: {
                    slidesPerView: 4,
                    spaceBetween: 10,
                    centeredSlides: false,
                    loop: false,
                    initialSlide: 0,
                },
            },
        });
    });


      
      document.addEventListener('click', function (event) {
        if (event.target.matches('.categories li')) {
            const currentFilter = event.target.getAttribute('data-filter');
             document.querySelectorAll('.categories li').forEach((li) => {
                li.classList.remove('active');
            });
            event.target.classList.add('active');
    
            document.querySelectorAll('.cw-sw17-swip-section').forEach((section) => {
                section.classList.remove('active_section');
            });
    
            const targetSection = document.querySelector(`.ds_${currentFilter}`);
            if (targetSection) {
                targetSection.classList.add('active_section');
            }
        }
    });
}, []);

 

  return (
    <>
    <div className="cw-sw17-box">
  <h1 className="cw-sw17-heading">See SketchWow In Action</h1>


 <ul className="categories">

        <li className="active"  data-filter="popular">Popular</li>
        <li data-filter="ai">Powered By AI</li>
        <li data-filter="template">Pick A Template</li>
        <li data-filter="scratch">Design From Scratch</li>
    </ul>



    <div className="cw-sw17-swip-section ds_popular active_section">
 
      <div className="cw-sw17-swip-section-main">
    <div className="swiper-button-prev"><img src="/cwfiles/yt_shortvids/ar-left.png" alt=""/></div>
    <div className="swiper-button-next"><img src="/cwfiles/yt_shortvids/ar-right.png" alt=""/></div>
    <div className="swiper cw-sw17-swip-container">
    
      <div className="swiper-wrapper">
      
 
        <div className="swiper-slide cw-sw17-swiper-box" data-filter="popular">
       <div className="voomly-embed" data-id="EAxBQDaG8BjvQxQYX3Ja1at3TU0HcxYi8DNBsAO6TTqAE1RyL" data-ratio="0.562500" data-type="v" data-skin-color="rgba(254,218,83,1)" data-shadow="" ></div>
        </div>
        <div className="swiper-slide cw-sw17-swiper-box" data-filter="popular">
        <div className="voomly-embed" data-id="wMyNbHg7cJcUVyFkMkcfIpI8iTVARlMqn2aADRZMbc5mNvl8b" data-ratio="0.562500" data-type="v" data-skin-color="rgba(254,218,83,1)" data-shadow=""></div>
        </div>

        <div className="swiper-slide cw-sw17-swiper-box" data-filter="popular">
       <div className="voomly-embed" data-id="XABZ2cgKvElenKgRlcEiHkKRqUecKDCchUsOInJMSpSac3B6P" data-ratio="0.562500" data-type="v" data-skin-color="rgba(254,218,83,1)" data-shadow=""></div>
        </div>
        
        <div className="swiper-slide cw-sw17-swiper-box" data-filter="popular">
         <div className="voomly-embed" data-id="G4TTppDbwm0JVNUUDIVouY5EdXBA9Fo7F6YVIGocK7jGHwVFD" data-ratio="0.562500" data-type="v" data-skin-color="rgba(254,218,83,1)" data-shadow=""></div>
        </div>
        
        <div className="swiper-slide cw-sw17-swiper-box" data-filter="popular">
        <div className="voomly-embed" data-id="l4igzt8KnTUvLwHaPDhAY8AvBJM7ATwz3D1lSy5cYKryIXE7M" data-ratio="0.562500" data-type="v" data-skin-color="rgba(254,218,83,1)" data-shadow=""></div>
        </div>

        <div className="swiper-slide cw-sw17-swiper-box" data-filter="popular">
       <div className="voomly-embed" data-id="q18Gyp-DhJ0H3F4e1oQysb7fCko9MOQaG99FFprcNgdOhwqP3" data-ratio="0.562500" data-type="v" data-skin-color="rgba(254,218,83,1)" data-shadow=""></div>
        </div>

        <div className="swiper-slide cw-sw17-swiper-box" data-filter="popular">
         <div className="voomly-embed" data-id="wtMWKYXJDNcq-RdXzUqPSe8z6QASZAVDKGLFfyhyCVMSTpUF6" data-ratio="0.562500" data-type="v" data-skin-color="rgba(254,218,83,1)" data-shadow=""></div>
        </div>
      </div>
    </div>
    </div>
  
  </div>

 

 <div className="cw-sw17-swip-section ds_ai">
 
    <div className="cw-sw17-swip-section-main">
 <div className="swiper-button-prev"><img src="/cwfiles/yt_shortvids/ar-left.png" alt=""/></div>
  <div className="swiper-button-next"><img src="/cwfiles/yt_shortvids/ar-right.png" alt=""/></div>
  <div className="swiper cw-sw17-swip-container">
   
    <div className="swiper-wrapper">

 <div className="swiper-slide cw-sw17-swiper-box" data-filter="ai">
    <div className="voomly-embed" data-id="SRZJ2ZkbzquI2AuK1lRm43DNbDkkwDte1gdxFn04FNOTU213P" data-ratio="0.562500" data-type="v" data-skin-color="rgba(254,218,83,1)" data-shadow=""></div>
        </div>

      <div className="swiper-slide cw-sw17-swiper-box" data-filter="ai">
       <div className="voomly-embed" data-id="SIwxmw7URKrfmQyL5oAIU9dPM42IsD2wmUxRioAHYxqfGllL2" data-ratio="0.562061" data-type="v" data-skin-color="rgba(254,218,83,1)" data-shadow=""></div>

        </div>

         <div className="swiper-slide cw-sw17-swiper-box" data-filter="ai">
       <div className="voomly-embed" data-id="AIIVRO7AihaT1FG4blCGaDNiEDT4VU4tDkNFLpT8L3hDG0V-9" data-ratio="0.562061" data-type="v" data-skin-color="rgba(254,218,83,1)" data-shadow=""></div>
        </div>
</div>
    </div>
    </div>
  
  </div>


 



 <div className="cw-sw17-swip-section ds_template">
 
    <div className="cw-sw17-swip-section-main">
   <div className="swiper-button-prev"><img src="/cwfiles/yt_shortvids/ar-left.png" alt=""/></div>
  <div className="swiper-button-next"><img src="/cwfiles/yt_shortvids/ar-right.png" alt=""/></div>
  <div className="swiper cw-sw17-swip-container">
 
    <div className="swiper-wrapper">


 <div className="swiper-slide cw-sw17-swiper-box" data-filter="template">
       <div className="voomly-embed" data-id="CErxV9ANRcfZnUtHxlgjpcSamAmaXyHLxH5jYHZ93BEzdl7kg" data-ratio="0.562061" data-type="v" data-skin-color="rgba(254,218,83,1)" data-shadow=""></div>
        </div>

        <div className="swiper-slide cw-sw17-swiper-box" data-filter="template">
        <div className="voomly-embed" data-id="0g1MBUQfnwTK3QqfZk1gv1bYiF1aszHSGoz2c14HhN6rJStAe" data-ratio="0.562061" data-type="v" data-skin-color="rgba(254,218,83,1)" data-shadow=""></div></div>
           
           
            <div className="swiper-slide cw-sw17-swiper-box" data-filter="template">
            <div className="voomly-embed" data-id="3afCBxbu0VzDAkZRG9dtg9puhNhBF7uVAjzCiFRNcbdZJslKI" data-ratio="0.562061" data-type="v" data-skin-color="rgba(254,218,83,1)" data-shadow=""></div></div>

      
          </div>
        </div>
        </div>
      
      </div>

 

<div className="cw-sw17-swip-section ds_scratch">
 
    <div className="cw-sw17-swip-section-main">
   <div className="swiper-button-prev"><img src="/cwfiles/yt_shortvids/ar-left.png" alt=""/></div>
  <div className="swiper-button-next"><img src="/cwfiles/yt_shortvids/ar-right.png" alt=""/></div>
  <div className="swiper cw-sw17-swip-container">
 
    <div className="swiper-wrapper">


                   <div className="swiper-slide cw-sw17-swiper-box" data-filter="scratch">
                <div className="voomly-embed" data-id="koiDXVCI97xTF1wQV1Fk2PNyOWhOhgbk6lddWvBESO0SU108M" data-ratio="0.562061" data-type="v" data-skin-color="rgba(254,218,83,1)" data-shadow=""></div></div>

                   <div className="swiper-slide cw-sw17-swiper-box" data-filter="scratch">
            <div className="voomly-embed" data-id="L1NQEBGWtsaAzJVCUxwhrcuoUzxBYBi2H3vgbC8YThynEmZoD" data-ratio="0.562061" data-type="v" data-skin-color="rgba(254,218,83,1)" data-shadow=""></div></div>

                   <div className="swiper-slide cw-sw17-swiper-box" data-filter="scratch">
            <div className="voomly-embed" data-id="KgbOB50e1NIYVx0AHFwtWo8qRn9IRLEiqo8NZwp18K4O3UkDM" data-ratio="0.562061" data-type="v" data-skin-color="rgba(254,218,83,1)" data-shadow=""></div></div>

                   <div className="swiper-slide cw-sw17-swiper-box" data-filter="scratch">
            <div className="voomly-embed" data-id="koXLmQesllDG3xoXRR8tz0Yp-QaUqBpbI1y1YU7ZNe5rUl0MK" data-ratio="0.562061" data-type="v" data-skin-color="rgba(254,218,83,1)" data-shadow=""></div></div>


      </div>
    </div>
    </div>
  
  </div>
</div>

 {/* <div className="cw-sw12-box">

<h1 className="cw-sw12-heading">See SketchWow In Action</h1>

<ul className="categories">

<li className="active"  data-filter="popular">Popular</li>
<li data-filter="ai">Powered By AI</li>
<li data-filter="template">Pick A Template</li>
<li data-filter="scratch">Design From Scratch</li>
</ul>


<div className="cw-sw12-swip-section">
 
    <div className="cw-sw12-swip-section-main">
        <div className="swiper-button-prev"><img src="/cwfiles/yt_shortvids/ar-left.png" alt=""/></div>
        <div className="swiper-button-next"><img src="/cwfiles/yt_shortvids/ar-right.png" alt=""/></div>
        
        <div className="swiper cw-sw12-swip-container swiper-my-choicex1">
            <div className="swiper-wrapper">
                
                    <div className="swiper-slide cw-sw12-swiper-box">
                        <div className="voomly-embed css-voomly-embed" data-id="EAxBQDaG8BjvQxQYX3Ja1at3TU0HcxYi8DNBsAO6TTqAE1RyL" data-ratio="0.562500" data-type="v" data-skin-color="rgba(254,218,83,1)" data-shadow=""></div>
                    </div>

                    <div className="swiper-slide cw-sw12-swiper-box">
                        <div className="voomly-embed css-voomly-embed" data-id="wMyNbHg7cJcUVyFkMkcfIpI8iTVARlMqn2aADRZMbc5mNvl8b" data-ratio="0.562500" data-type="v" data-skin-color="rgba(254,218,83,1)" data-shadow=""></div>
                    </div>

                    <div className="swiper-slide cw-sw12-swiper-box">
                        <div className="voomly-embed css-voomly-embed" data-id="XABZ2cgKvElenKgRlcEiHkKRqUecKDCchUsOInJMSpSac3B6P" data-ratio="0.562500" data-type="v" data-skin-color="rgba(254,218,83,1)" data-shadow=""></div>
                    </div>
      
                    <div className="swiper-slide cw-sw12-swiper-box">
                        <div className="voomly-embed css-voomly-embed" data-id="G4TTppDbwm0JVNUUDIVouY5EdXBA9Fo7F6YVIGocK7jGHwVFD" data-ratio="0.562500" data-type="v" data-skin-color="rgba(254,218,83,1)" data-shadow=""></div>
                    </div>
      
                    <div className="swiper-slide cw-sw12-swiper-box">
                        <div className="voomly-embed css-voomly-embed" data-id="l4igzt8KnTUvLwHaPDhAY8AvBJM7ATwz3D1lSy5cYKryIXE7M" data-ratio="0.562500" data-type="v" data-skin-color="rgba(254,218,83,1)" data-shadow=""></div>
                    </div>

                    <div className="swiper-slide cw-sw12-swiper-box">
                        <div className="voomly-embed css-voomly-embed" data-id="q18Gyp-DhJ0H3F4e1oQysb7fCko9MOQaG99FFprcNgdOhwqP3" data-ratio="0.562500" data-type="v" data-skin-color="rgba(254,218,83,1)" data-shadow=""></div>
                    </div>

                    <div className="swiper-slide cw-sw12-swiper-box">
                        <div className="voomly-embed css-voomly-embed" data-id="wtMWKYXJDNcq-RdXzUqPSe8z6QASZAVDKGLFfyhyCVMSTpUF6" data-ratio="0.562500" data-type="v" data-skin-color="rgba(254,218,83,1)" data-shadow=""></div>
                    </div>
            </div>
        </div>
  </div>
</div>
</div> */}
 
    </>
            
  );

  
};
