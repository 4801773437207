import React, { ReactElement, ReactNode, useRef, useEffect,useState } from 'react';
import * as Styled from './styles';
import { useVisibilityDetection } from '../../hooks/useVisibilityDetection';
import { css } from '@emotion/react';
import { useLocation } from '@reach/router';

interface IntroProps {
  styles?: any;
  topTitle: string | ReactNode;
  title: string | ReactNode;
  subTitle: string | ReactNode;
  subTitle2: string | ReactNode;
  withSignUp: boolean;
  withFooter: boolean;
}

export const Intro = ({ styles, topTitle, title, subTitle, subTitle2, withSignUp, withFooter }: IntroProps): ReactElement => {
  const introRef = useRef(null);
  const { isVisible } = useVisibilityDetection(introRef);
  const [SWCode, setSWCode] = useState(null);
  const [queryStr, setQueryStr] = useState(null);
  const location = useLocation();
  
  useEffect(()=>{
    const deal_qstr =  window.location.href.split('?')[1];

    setSWCode(localStorage.getItem('swcode'));
    if(typeof deal_qstr !== 'undefined') 
      setQueryStr(deal_qstr);
   }, []);


  let links = () => {

    if(location.pathname == '/' || location.pathname == '/pricing/' || location.pathname == '/pricing'){
      return(<Styled.Button href="https://sketchwow.com/pricing">Get SketchWow</Styled.Button>)
    }else if(location.pathname == '/trial/' || location.pathname == '/trial'){
      return(<Styled.Button  href="https://checkout.sketchwow.com/?add-to-cart=2064">Get SketchWow</Styled.Button>)
    }else if(location.pathname == '/sale/' || location.pathname == '/sale'){
      return(<Styled.Button  href={"https://checkout.sketchwow.com/?add-to-cart=407&cg2=1&swp=sl" + (queryStr ? "&" + queryStr : "")} >Get SketchWow</Styled.Button>)
    }else if(location.pathname == '/save/' || location.pathname == '/save'){
      return(<Styled.Button  href={"https://checkout.sketchwow.com/?add-to-cart=407&cg2=1&swp=sv" + (queryStr ? "&" + queryStr : "")}>Get SketchWow</Styled.Button>)
    }else if(location.pathname == '/offer/' || location.pathname == '/offer'){
      return(<Styled.Button  href={"https://checkout.sketchwow.com/?add-to-cart=407&cg2=1&swp=of" + (queryStr ? "&" + queryStr : "")}>Get SketchWow</Styled.Button>)
    }else if(location.pathname == '/lifetime/' || location.pathname == '/lifetime'){
      return(<Styled.Button  href={"https://checkout.sketchwow.com/?add-to-cart=407&cg2=1&swp=lt" + (queryStr ? "&" + queryStr : "")}>Get SketchWow</Styled.Button>)
    }else if(location.pathname == '/lifetime-deal/' || location.pathname == '/lifetime-deal'){
      return(<Styled.Button  href={"https://checkout.sketchwow.com/?add-to-cart=407&cg2=1&swp=ltd" + (queryStr ? "&" + queryStr : "")}>Get SketchWow</Styled.Button>)
    }else if(location.pathname == '/deal/' || location.pathname == '/deal'){
      return(<Styled.Button  href={"https://checkout.sketchwow.com/?add-to-cart=407&cg2=1&swp=dl" + (queryStr ? "&" + queryStr : "")}>Get SketchWow</Styled.Button>)
    }else if((location.pathname == '/special/' || location.pathname == '/special') && SWCode){
      return(<Styled.Button  href={"https://checkout.sketchwow.com/?add-to-cart=407&cg2=1&swp=sp&code="+SWCode + (queryStr ? "&" + queryStr : "")}>Get SketchWow</Styled.Button>)
      //return(<Styled.Button  href={"https://checkout.sketchwow.com/?add-to-cart=407&cg2=1&swp=sp&coupon_code=BYTE10OFF&code="+SWCode + (queryStr ? "&" + queryStr : "")}>Get SketchWow</Styled.Button>)
    }else if(location.pathname == '/promo/' || location.pathname == '/promo'){
      return(<Styled.Button  href={"https://checkout.sketchwow.com/?add-to-cart=407&cg2=1&swp=pr" + (queryStr ? "&" + queryStr : "")}>Get SketchWow</Styled.Button>)
    }else if(location.pathname == '/yt-deal/' || location.pathname == '/yt-deal'){
      return(<Styled.Button  href={"https://checkout.sketchwow.com/?add-to-cart=407&cg2=1&swp=yt" + (queryStr ? "&" + queryStr : "")}>Get SketchWow</Styled.Button>)
    }else if(location.pathname == '/' || location.pathname == ''){
      return(<Styled.Button href="https://sketchwow.com/pricing-new">Get SketchWow</Styled.Button>)
    }else{
      return(<Styled.Button  href={"https://checkout.sketchwow.com/?add-to-cart=407&cg2=1" + (queryStr ? "&" + queryStr : "")}>Get SketchWow</Styled.Button>)
    }

  }

  return (
    <Styled.Wrapper 
      ref={introRef} 
      isVisible={isVisible} 
      id="Intro"
      css={css`
      ${styles}
    `}>
       
      <Styled.Heading>
      <Styled.topTitle>{topTitle}</Styled.topTitle>
        <Styled.Title>{title}</Styled.Title>
      </Styled.Heading>
      <Styled.Content>{subTitle}</Styled.Content>
      {subTitle2 && <Styled.Content 
          css={css`margin-top: -30px !important; 
                  @media screen and (max-width: 515px) {
                    margin-top: -20px !important
                }`}>{subTitle2}</Styled.Content>}
      {withSignUp && links()}
      {withFooter &&
      <Styled.Footer>
          <p>
            SketchWow works flawlessly on <Styled.Doodle>Windows & Macs.</Styled.Doodle>
          </p>
        </Styled.Footer>}
    </Styled.Wrapper>
  );
};
