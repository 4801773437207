import React, { ReactElement, useEffect } from 'react';
import { Layout } from '../components/Layout/Layout';
import { Intro } from '../modules/Intro/Intro';

const HowTheTop20CrushPage = (): ReactElement => (
    useEffect(()=>{
        setTimeout(function(){
            window.location.replace('https://rebrand.ly/How-The-Top-20-Crush')
        }, 100)
        

        }, []),

        <Layout>
        <Intro
      title={
        <> </>
      }
      subTitle={<>How The Top 20 Crush</>}
      
    />
  </Layout>
);

export default HowTheTop20CrushPage;
