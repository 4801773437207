import styled from '@emotion/styled';
import { Link } from 'gatsby';
import { breakpoints, colors, dimensions } from "../../styles/variables";
import { css } from '@emotion/react';
 

export const Wrapper = styled.div`
  display: flex;
  margin-bottom: 18px;
  padding: 32px 32px 14px 32px;
  padding: 20px 20px 2px 20px;
  top: 0;
  z-index: 100;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
 
  @media screen and (max-width: ${breakpoints.md}px) {
    padding: 20px 20px 2px 20px;
  }

`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: ${breakpoints.sm}px) {
    div.rave-reviews{
      width: 100%;
    }

    div.rave-reviews img{
      height: 60px;
    }

    div.reviews{
      float: left;
      width: 48%;
      margin-top: 20px;
      height: 24px;
    }

    img{
      height: 30px;
    }
    
    display: block;
  }
`;

export const Review = styled.div`
  width: 23%;
  float left;
  
  img{
    object-fit: contain;
    width: 100%;
  }


`;

export const VerticalBorder = styled.div`
  background-color:#ddd;
  color:#ddd;
  -webkit-transform:rotate(90deg);
  width:100px;
  height:2px;
  margin-left:-32px;
  margin-right: -32px;
  border:2px;

  @media screen and (max-width: ${breakpoints.md}px) {
    display: none;
  }

  @media screen and (max-width: ${breakpoints.lg}px) {
    margin-left:-30px;
  margin-right: -30px;
  }
`;
