import React, { ReactElement } from 'react';
import { useWindowScroll } from 'react-use';
import * as Styled from './styles';
import { css } from '@emotion/react';
import { useLocation } from '@reach/router';


export const ReviewBanner = (): ReactElement => {
 
  const location = useLocation(); 

  return (
    <Styled.Wrapper  >
      <Styled.Container>
        <Styled.Review className='rave-reviews'><img height={70} src= "/salespage/review banner/rave-reviews-update.png"/></Styled.Review>
        <Styled.VerticalBorder/>
        <Styled.Review className='reviews' ><img src="/salespage/review banner/capterra2a.svg"/></Styled.Review>
        <Styled.Review className='reviews'><img src="/salespage/review banner/G2.2a.svg"/></Styled.Review>
        {/* <Styled.Review className='reviews'><img src="/salespage/review banner/trustpilot2a.svg"/></Styled.Review> */}
        
      </Styled.Container>
 
    </Styled.Wrapper>
  );
};
