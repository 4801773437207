import React, { ReactElement, useRef, useEffect, useState } from 'react'; 
import "./style_capterra.css"
import { useLocation } from '@reach/router';
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';


export const CWCapterraReviews = (): ReactElement => {
 
  const location = useLocation();
 
  useEffect(()=>{
 
        var swiperv99 = new Swiper(".swiper-my-choicex", {
         direction: 'horizontal',
              loop: true,
              slidesPerView: 1,  
              spaceBetween: 10,   
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },
            breakpoints: {
                  0: {
                      slidesPerView: 1,  
                      loop:true,
                  },
                  1024: {
                      slidesPerView: 4,   
                         loop: false,
                  },
              },
        });
       
        const deal_qstr =  window.location.href.split('?')[1];
        let links = document.getElementsByClassName("capterra-pricing-link");
        let link = "/pricing/?swp=home&cg2=1";
        if(location.pathname == '/sale/' || location.pathname == '/sale')
          link = "/pricing/?cg2=1&swp=sl"; //link = "#DealPricing"; //
        else if(location.pathname == '/save/' || location.pathname == '/save')
           link = "/pricing/?cg2=1&swp=sv";//link = "#DealPricing"; //
        else if(location.pathname == '/offer/' || location.pathname == '/offer')
           link = "/pricing/?cg2=1&swp=of";//link = "#DealPricing"; //
        else if(location.pathname == '/lifetime/' || location.pathname == '/lifetime')
           link = "/pricing/?cg2=1&swp=lt";//link = "#DealPricing"; //
        else if(location.pathname == '/lifetime-deal/' || location.pathname == '/lifetime-deal')
          link = "/pricing/?cg2=1&swp=ltd";//link = "#DealPricing"; //
        else if(location.pathname == '/deal/' || location.pathname == '/deal')
           link = "/pricing/?cg2=1&swp=dl";//link = "#DealPricing"; //
        else if((location.pathname == '/special/' || location.pathname == '/special') && localStorage.getItem('swcode'))
            link = "https://checkout.sketchwow.com/?add-to-cart=407&cg2=1&swp=sp&code="+localStorage.getItem('swcode');//link = // link = "https://checkout.sketchwow.com/?add-to-cart=407&cg2=1&swp=sp&coupon_code=BYTE10OFF&code="+localStorage.getItem('swcode');//link = "#DealPricing"; //
        else if(location.pathname == '/promo/' || location.pathname == '/promo')
          link = "/pricing/?cg2=1&swp=pr";
        else if(location.pathname == '/yt-deal/' || location.pathname == '/yt-deal')
            link = "/pricing/?cg2=1&swp=yt";
    
        if(typeof deal_qstr !== 'undefined' &&  links !== null){
          let i;
          for (i = 0; i < links.length; i++) {
            links[i].href = link + '&'  + deal_qstr;
          }
        }else if(links !== null){
          let i;
          for (i = 0; i < links.length; i++) {
            links[i].href = link;
          }
        }
    

 
 

    }, []);

 

  return (
    <>
<div className="replace-trust-swiper">
              <div className="sw_head">What Customers Say About Us</div>
              <div className="review-rating-container">
                  <div className="review-rating">
                     <strong> Excellent   </strong>
                      <div className="stars">
                          <img src="/cwfiles/capterra_reviews/stars-rating-main_677fd5180753f.png" alt="Star" className="star-img"/>
                      </div>
                      <div className="reviews-info">
                           <strong> (289) </strong> reviews on
                          <img src="/cwfiles/capterra_reviews/capterra-logo.svg" alt="Capterra Logo" className="capterra-img"/>
                         
                      </div>
                  </div>
              </div>
      
      <div className="sw9-capterra">
       <button id="prevBtn" className="slider-btn">
                      <div className="swiper-button-prev">
                          <img src="/cwfiles/capterra_reviews/Aro.png" alt="Previous" className="sw_otp"/>
                      </div>
                  </button>
                   <button id="nextBtn" className="slider-btn">
                  <div className="swiper-button-next">
                  <img src="/cwfiles/capterra_reviews/Aro (1).png" alt="Previous" className="sw_otp"/>
                  </div>
                  </button>
              <div className="slider-wrapper">
                 
                  <div className="testimonial-cards-wrapper swiper swiper-my-choicex">
                      <div className="testimonial-cards swiper-wrapper">
                       
                          <div className="testimonial-card swiper-slide swiper-slide-cr">
                              <div className="cop_sw">
                                  <div className="rating"><img src="/cwfiles/capterra_reviews/stars-rating-main_677fd5180753f.png" alt="Rating Stars"/>
                                  </div>
                            
                              </div>
                              <div className="sw_heading">One of my favorite pieces of software!</div>
                              <div className="testimonial-text">
                                  I've introduced several people at work to SketchWow and we're actually using it in our HR dept to create flyers, etc. It's taken postings that people used to walk right by and ignore and made them eye catching and attention grabbing. We've had people comment about how much better they look than the ones that had been created previously.
                              </div>
      
                              <div className="customer-info">
                                  <div className="avatar_avatar_sw s1"><span className="">Bb</span></div>
                                  <div className="customer-name">Bob</div>
                              </div>
                          </div>
      
      
                 <div className="testimonial-card swiper-slide swiper-slide-cr">
                              <div className="cop_sw">
                                  <div className="rating"><img src="/cwfiles/capterra_reviews/stars-rating-main_677fd5180753f.png" alt="Rating Stars"/>
                                  </div>
                                  
                              </div>
      
                              <div className="sw_heading">Makes it easy to create striking and personal diagram and graphics</div>
                              <div className="testimonial-text">
                                I just love the style that SketchWow creates! I was looking for a way to create good looking graphics for my presentations that did not look like the plastic standard diagrams of most presentation software. And SketchWow delivers beautifully! I now create professional, yet unique, graphics to illustrate my points.
                              </div>
                              <div className="customer-info">
                                  <div className="avatar_avatar_sw s3"><span className="">OL</span></div>
                                  <div className="customer-name">Ola</div>
                              </div>
                          </div>
      
      
                          <div className="testimonial-card swiper-slide swiper-slide-cr">
                              <div className="cop_sw">
                                  <div className="rating"><img src="/cwfiles/capterra_reviews/stars-rating-main_677fd5180753f.png" alt="Rating Stars"/>
                                  </div>
                               
                              </div>
      
                              <div className="sw_heading">Perfect to bring across ideas and concepts</div>
                              <div className="testimonial-text">I frequently need to "sell" concepts and ideas internally to the C-level, and the sketches you can draw with SketchWow help bring across the idea !You have the idea, and SketchWow supports you to make the idea/concept look flashy and fresh - even animated.Great ways to get attention and bring across the message ! </div>
                             
                                  <div className="customer-info">
                                  <div className="avatar_avatar_sw s4"><span className="">PI</span></div>
                                  <div className="customer-name">Pierre</div>
                             
                              </div>
                          </div>
      
                          <div className="testimonial-card swiper-slide swiper-slide-cr">
                              <div className="cop_sw">
                                  <div className="rating"><img src="/cwfiles/capterra_reviews/stars-rating-main_677fd5180753f.png" alt="Rating Stars"/>
                                  </div>
                               
                              </div>
      
                              <div className="sw_heading">Life changing</div>
                              <div className="testimonial-text">
                                 I love it, it helps me market my business, create graphics for presentations, and graphics for posters I can print out for my office. </div>
                              <div className="customer-info">
                                  <div className="avatar_avatar_sw s5"><span className="">RO</span></div>
                                  <div className="customer-name">Roni</div>
                              </div>
                          </div>
      
                          <div className="testimonial-card swiper-slide swiper-slide-cr">
                              <div className="cop_sw">
                                  <div className="rating"><img src="/cwfiles/capterra_reviews/stars-rating-main_677fd5180753f.png" alt="Rating Stars"/>
                                  </div>
                                  
                              </div>
      
                              <div className="sw_heading">It's amazing for flowcharts and graphics - must buy</div>
                              <div className="testimonial-text">
                               Love the results I get in 5 minutes or less
                              </div>
                              <div className="customer-info">
                                  <div className="avatar_avatar_sw s6"><span className="">VR</span></div>
                                  <div className="customer-name">Verified Reviewer</div>
                              </div>
                          </div>
      
      
                          <div className="testimonial-card swiper-slide swiper-slide-cr">
                              <div className="cop_sw">
                                  <div className="rating"><img src="/cwfiles/capterra_reviews/stars-rating-main_677fd5180753f.png" alt="Rating Stars"/>
                                  </div>
                                
                              </div>
      
                              <div className="sw_heading">  SketchWow: Create Visually Engaging and Informative Diagrams in Minutes</div>
                              <div className="testimonial-text">
                                  Overall, great experience with SketchWow. I love the hand-drawn look, the ease of use, and the constant development and updates. I warmly recommend it to anyone needing to create visually engaging diagrams.
                              </div>
                              <div className="customer-info">
                                  <div className="avatar_avatar_sw s7"><span className="">SF</span></div>
                                  <div className="customer-name">Stef</div>
                              </div>
                          </div>
      
                          <div className="testimonial-card swiper-slide swiper-slide-cr">
                              <div className="cop_sw">
                                  <div className="rating"><img src="/cwfiles/capterra_reviews/stars-rating-main_677fd5180753f.png" alt="Rating Stars"/>
                                  </div>
                                  
                              </div>
      
                              <div className="sw_heading">Sketchwow? More like CONTENT WOW!</div>
                              <div className="testimonial-text">
                                  I LOVE SKETCHWOW. My content pops and has made me unique among my peers. I honestly don't use it enough. I have created videos, social media images, elements for other content and website/blog images.       </div>
                              <div className="customer-info">
                                  <div className="avatar_avatar_sw s8"><span className="">PA</span></div>
                                  <div className="customer-name">Paula</div>
                              </div>
                          </div>
      
                         
      
      
      
      
      
                         
      
                      </div>
                  </div>
                 </div>
              </div>
              <div className="sw-feature-first-second">
                  <div className="sw-feature-first-second-button">
                      <a href="https://sketchwow.com/pricing" className="css-10gfo7v eg4cuq44 capterra-pricing-link">Get Sketchwow Now</a>
                  </div>
                  <div className="sw-feature-first-second-list">
                      <div className="sw-feature-first-second-list-main">
                          <div className="sw-feature-first-second-list-content">
                              <div className="sw-feature-first-second-list-image">
                                  <img src="/cwfiles/capterra_reviews/feature-money1.png"/>
                              </div>
                              <div className="sw-feature-first-second-list-title">
                                  Includes FREE <br/> Software Updates
                              </div>
                          </div>
                          <div className="sw-feature-first-second-list-content">
                              <div className="sw-feature-first-second-list-image">
                                  <img src="/cwfiles/capterra_reviews/feature-money2.png"/>
                              </div>
                              <div className="sw-feature-first-second-list-title">
                                  30-Day moneyback <br/> guarantee
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div> 

   
    </>
            
  );

  
};
