import React, {
  ReactElement,
  useMemo,
  useRef,
  useState,
  useEffect,
} from "react";
import uniqueId from "lodash/uniqueId";
import { ReviewsItem } from "./types";
import * as Styled from "./styles";
import useVisibleOnScroll from "../../hooks/useVisibleOnScroll";
import  axios  from 'axios';

interface ReviewsProps {
  data: ReviewsItem[];
  title: ReactElement ;
  subtitle: ReactElement;
}

interface ReviewsDataItem extends ReviewsItem {
  id: string;
}

var ScrollInterval;

export const Reviews = ({
  data,
  title,
  subtitle,
}: ReviewsProps): ReactElement => {
  const memoizedData = useMemo(
    () =>
      data.map(
        (item): ReviewsDataItem => ({
          ...item,
          id: uniqueId(),
        })
      ),
    [data]
  );


  // let [imgSrc, setImgSrc] = useState("/reviews/profiles/placeholder.png");

  // const CheckImage = (path) => {
  //   CheckIfImageExists(path, (exists) => {
  //     //console.log(exists, path)
  //     if (exists) {
  //        setImgSrc = path;
  //        //return true;
  //     } else {
  //        setImgSrc = "/reviews/profiles/placeholder.png";
  //       //return false;
  //     }
  //   });
  //   // axios
  //   //   .get(path)
  //   //   .then(() => {
  //   //     //console.log(path)
  //   //     return true;
  //   //   })
  //   //   .catch(() => {
  //   //     console.log(path)
  //   //     return false;
  //   //   });
  // }

  // const CheckIfImageExists = (url, callback) => {
  //   const img = new Image();
  //   img.src = url;

  //   if (img.complete) {
  //     callback(true);
  //   } else {
  //     img.onload = () => {
  //       callback(true);
  //     };
      
  //     img.onerror = () => {
  //       callback(false);
  //     };
  //   }
  // }




  //const [activeQuote, setActiveQuote] = useState(memoizedData[0].id);
  const testimonialsRef = useRef(null);
  const { isVisible } = useVisibleOnScroll(testimonialsRef);

  const cards = () => {
    let reviews = [];
    memoizedData.map((review, index) => {
      
      if (review.type == "banner") {
        reviews.push(<Styled.Banner key={review.id} className="review-content">
          
          <Styled.BannerQuote>
          <Styled.BannerStars>
            <img src="/reviews/star.svg"/>
            <img src="/reviews/star.svg"/>
            <img src="/reviews/star.svg"/>
            <img src="/reviews/star.svg"/>
            <img src="/reviews/star.svg"/>
          </Styled.BannerStars><br/><br/>
            {/*review.comment.split('/n').map(comment => ( */ }
                <Styled.BannerQuoteContent title={review.comment.split('/n').join(' ')}>
                "
                {
                    //comment
               
                    review.comment.split('/n').join(' ')
                }
                "
                </Styled.BannerQuoteContent>
              { /*))*/ }
              <Styled.BannerNameContainer>
                
                <Styled.Author> 
                  <img src={"/reviews/profiles/"+review.name+".png"}/> {review.name}</Styled.Author>
                {review.emoji != "" && 
                  <Styled.Emoji><img src={"/reviews/"+review.emoji+".svg"}/></Styled.Emoji>
                }
              </Styled.BannerNameContainer>
          </Styled.BannerQuote>
          <Styled.BannerIcons>
              
              <img className="qoute" src="/reviews/qoute.svg"/>
              <img className="megaphone" src="/reviews/megaphone.svg"/>
          </Styled.BannerIcons>
        </Styled.Banner>)
      }else{
        reviews.push(<Styled.Card key={review.id} className="review-content">
          <Styled.Quote>
            {/*review.comment.split('/n').map(comment => ( */ }
                <Styled.QuoteContent>
                "
                {
                    //comment
               
                    review.comment.split('/n').join(' ')
                }
                "
                </Styled.QuoteContent>
              { /*))*/ }

          </Styled.Quote>
          <Styled.NameContainer>
             
             <Styled.Author> 
               <img src={"/reviews/profiles/"+review.name+".png"}/> {review.name}</Styled.Author>
             {review.emoji != "" && 
               <Styled.Emoji><img src={"/reviews/"+review.emoji+".svg"}/></Styled.Emoji>
             }
           </Styled.NameContainer>
        </Styled.Card>)
      }
    })

    return reviews;
  }


  useEffect(() => {
    // First, define a helper function.
// function animateScroll(duration) {
//   var someElement = document.getElementById("reviews-card")
//   var start = someElement.scrollTop;
//   var end = someElement.scrollHeight - someElement.clientHeight;
//   var change = end - start;
//   var increment = 10;
//   function easeInOut(currentTime, start, change, duration) {
//     // by Robert Penner
//     currentTime /= duration / 2;
//     if (currentTime < 1) {
//       return change / 2 * currentTime * currentTime + start;
//     }
//     currentTime -= 1;
//     return -change / 2 * (currentTime * (currentTime - 2) - 1) + start;
//   }
//   function animate(elapsedTime) {
//     elapsedTime += increment;
//     var position = easeInOut(elapsedTime, start, change, duration);
//     console.log(elapsedTime, position)
//     someElement.scrollTop = position;
//     if (elapsedTime < duration) {
//       setTimeout(function() {
//         animate(elapsedTime);
//       }, increment)
//     }
//   }
//   animate(0);
// }
// // Here's our main callback function we passed to the observer
// function scrollToBottom() {
//   var duration = 25000 // Or however many milliseconds you want to scroll to last
//   animateScroll(duration);
// }

//scrollToBottom()


var rev_card = document.getElementById('reviews-card');
var rev_content = document.getElementsByClassName('review-content');
rev_card.onmouseover = pauseScroll;
rev_card.onmouseout = resumeScroll;
rev_card.scrollTop = 0;
var ReachedMaxScroll = false;
var PreviousScrollTop  = 0;
var ScrollRate = 10;
ScrollInterval =  setInterval(scrollCards, ScrollRate);
// clearInterval(ScrollInterval);
// console.log(ScrollInterval)

function scrollCards() {
  if (!ReachedMaxScroll) {
    rev_card.scrollTop = PreviousScrollTop;
    PreviousScrollTop++;
    ReachedMaxScroll = rev_card.scrollTop >= (rev_card.scrollHeight - rev_card.offsetHeight);
   
  }
  else {
    rev_card.scrollTop = PreviousScrollTop = 0;
    ReachedMaxScroll = false;
    clearInterval(ScrollInterval);
  }
}

function pauseScroll() {
  // console.log('pause')
  // console.log(ScrollInterval)
  clearInterval(ScrollInterval);
  // console.log(ScrollInterval)
}

function resumeScroll() {
  // console.log('resume')
  PreviousScrollTop = rev_card.scrollTop;
  if (!ReachedMaxScroll) 
    ScrollInterval = setInterval(scrollCards, ScrollRate);
  // /console.log(ScrollInterval)
}

// function initScroll(){
//   ScrollInterval = setInterval(scrollCards, ScrollRate);
// }

// setTimeout(initScroll, 1000)

}, [])


  return (
    <Styled.Wrapper isVisible={isVisible} ref={testimonialsRef}>
      {/* <Styled.WrapperBG>test</Styled.WrapperBG> */}
      <Styled.Heading>
        <Styled.Title>{title}</Styled.Title>
        <Styled.Subtitle>{subtitle}</Styled.Subtitle>
      </Styled.Heading>
          <Styled.Cards>
            <Styled.CardContainer id="reviews-card">
              {cards()}
            </Styled.CardContainer>
          </Styled.Cards>
          <Styled.Doodle />

      { /*
      
        <Styled.Arrow
          src="/testimonials-arrow.webp"
          srcSet="/testimonials-arrow.webp 1x, /testimonials-arrow@2x.webp 2x"
          alt="Arrow"
          loading="lazy"
    /> */}
    </Styled.Wrapper>
  );
};
