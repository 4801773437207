import React, { ReactElement, useEffect } from 'react';
import { Layout } from '../components/Layout/Layout';
import { Intro } from '../modules/Intro/Intro';

const ClassroomTeachingStrategiesPage = (): ReactElement => (
    useEffect(()=>{
        setTimeout(function(){
            window.location.replace('https://rebrand.ly/108-Classroom-Teaching-Strategies')
        }, 100)
        

        }, []),

        <Layout>
        <Intro
      title={
        <> </>
      }
      subTitle={<>108 Classroom Teaching Strategies</>}
      
    />
  </Layout>
);

export default ClassroomTeachingStrategiesPage;
