import styled from '@emotion/styled';
import { breakpoints, colors, dimensions } from '../../styles/variables';
import { Visibility } from "../../types";

const TOP_BACKGROUND_HEIGHT = "1.45vw";
const BOTTOM_BACKGROUND_HEIGHT = "1.88vw";
export const SafariWrapper = styled.div`
  width: 100%;
  overflow: hidden;
`;

export const Wrapper = styled.section<Visibility>`
  margin: auto;
  padding-bottom: ${BOTTOM_BACKGROUND_HEIGHT};
  margin-top: 100px;
  //margin-bottom: calc(95px + ${BOTTOM_BACKGROUND_HEIGHT});
  //padding: 0 ${dimensions.containerPadding}px;
  position: relative;
  z-index: 2;
  max-width: 1172px;
 
  @media screen and (max-width: ${breakpoints.lg}px) {
    height: auto;
    //margin-bottom: calc(60px + ${BOTTOM_BACKGROUND_HEIGHT});
    margin-bottom:0;
    
  }


 
`;

export const Heading = styled.header`
  width: 100%;
  margin-bottom: 33px;
  text-align:center;

  &:before {
    content: "";
    position: absolute;
    background-size: contain;
    width: 250px;
    height: 200px; 
    background: url('/salespage/zizzling bonus/party-popper-left.svg') no-repeat center 0;
    left: -100px;
    top: 55px;

    @media screen and (max-width: ${breakpoints.xl}px) {
      width: 150px;
      height: 100px; 
      left: -20px;
      top: 75px;
      background-size: contain;
      z-index: -1;
    }
    @media screen and (max-width: ${breakpoints.lg}px) {
      top: 70px;
    }
    
    @media screen and (max-width: ${breakpoints.sm}px) {
      width: 100px;
      height: 50px; 
      top: 45px;
    }

    @media screen and (min-width: 540px) and (max-width: 540px) {
      top: 70px;
    }
  }


  &:after {
    content: "";
    position: absolute;
    background-size: contain;
    width: 250px;
    height: 200px; 
    background: url('/salespage/zizzling bonus/party-popper-right.svg') no-repeat center 0;
    right: -100px;
    top: 55px;
 
    @media screen and (max-width: ${breakpoints.xl}px) {
      width: 150px;
      height: 100px; 
      right: -20px;
      top: 75px;
      background-size: contain;
      z-index: -1;
    }
    @media screen and (max-width: ${breakpoints.lg}px) {
      top: 70px;
    }
    @media screen and (max-width: ${breakpoints.sm}px) {
      width: 100px;
      height: 50px; 
      top: 45px;
    }
    @media screen and (min-width: 540px) and (max-width: 540px) {
      top: 70px;
    }
  }
`;

export const HeadingTitle = styled.h2`
  margin: 0;
  color: ${colors.darkNavyBlue};
  font-size: ${dimensions.fontSize.heading}px;
  font-weight: 900;
  line-height: ${dimensions.lineHeight.heading};
   margin-bottom: 40px;
  @media screen and (max-width: ${breakpoints.lg}px) {
    font-size: ${dimensions.fontSize.heading * 0.7}px;
  }
  @media screen and (max-width: ${breakpoints.sm}px) {
    font-size:23px;
  }

    @media screen and (max-width: 420px) {
    font-size:20px;
  }
`;

export const SubTitle = styled.h3`
  margin: 0 0 19px 0;
  /*color: #9d978b;*/
  font-weight: 700;
  font-size: 25px;
  line-height: ${dimensions.lineHeight.large};

  @media screen and (max-width: ${breakpoints.sm}px) {
    font-size:23px;
  }

  @media screen and (max-width: 420px) {
    font-size:20px;
  }
`;
 
export const LeftColumn = styled.div`
  padding-right: 20px;
`;

export const Content = styled.div`
  color: ${colors.lightGray};
  font-size: ${dimensions.fontSize.medium}px;
  line-height: 1.63;

  p {
    margin: 26px 0;
  }

  @media screen and (max-width: ${breakpoints.xl}px) {
    font-size: ${dimensions.fontSize.medium * 0.9}px;
  }
`;

export const ContentHeading = styled.div`
  width: 100%

  div{
    width: 70%;
    float: left;
  }

  img{
    width: 150px;
    float: right;
    margin-top: -16px;
    @media screen and (max-width: ${breakpoints.md}px) {
      margin-top: -10px;
      width: 130px;
    }
  }
`;

export const ContentSubTitle = styled.h5`
  color: #1f62ff;
  font-weight: 600;
  font-size: 20px;
  line-height: ${dimensions.lineHeight.large};
  margin-top: -20px;
`;


export const RightColumn = styled.figure`
  @media screen and (max-width: ${breakpoints.md}px) {
    margin: 0;
    padding: 0;
  }
`;

export const Illustration = styled.img`
  transform: translate(21px, -12px);

  @media screen and (max-width: ${breakpoints.lg}px) {
    max-width: 100%;
    transform: none;
  }
`;

export const VidInsideIllustration = styled.div`

  width: 505px;
  height: 310px;
  position: absolute;
  z-index: 999;
  margin-top: 304px;
  margin-left: 89px;

  iframe{
    width: 100%;
    height: 100%;
  }

  video{
    min-width: 100px !important;
    width: 100%;
    /*height: 370px;*/
    border: none;
  }


   @media screen and (max-width: ${breakpoints.xl}px) {
 
     z-index: 999;
     width: 371px;
    margin-left: 74px;
    margin-top: 215px;
  }

    @media screen and (max-width: 1024px) {
 
    margin-top: 222px;
  }

  @media screen and (max-width: ${breakpoints.lg}px) {
    width: 506px; 
    margin-top: 206px;
  }

  @media screen and (max-width: ${breakpoints.lg}px) {

    margin-top: 344px;
    margin-left: 68px
  }
  
  @media screen and (max-width: ${breakpoints.md}px) {
    width: 506px; 
    margin-left: 70px;
     margin-top: 345px;
  }
  
  @media screen and (max-width: ${breakpoints.sm}px) {
     width: 371px; 
    margin-left: 52px;
     margin-top: 263px;
  }

  @media screen and (max-width: 450px) {
  
      width: 286px;
      margin-left: 40px;
      margin-top: 215px;
    

  }

   @media screen and (max-width: 420px) {
  
      width: 272px;
      margin-left: 38px;
      margin-top: 203px;
     

  }

    @media screen and (max-width: 400px) {
 
      width: 257px;
      margin-left: 34px;
      margin-top: 190px;
  

  }

  @media screen and (max-width: 375px) {
 
      width: 245px;
      margin-left: 34px;
      margin-top: 184px;
     

  }

    @media screen and (max-width: 360px) {
 
      width: 232px;
      margin-left: 33px;
      margin-top: 177px;
 

  }

      @media screen and (max-width: 345px) {
 
      width: 211px;
      margin-left: 30px;
      margin-top: 167px;
 

  }

 
`;

export const PenScribble = styled.div`
  background: url("/salespage/2nd section/pen-scribble.svg") no-repeat; 
  width: 400px;
  height: 100px;
  margin: auto;
  margin-top: 40px;
  @media screen and (max-width: ${breakpoints.sm}px) {
    width: 200px;height:40px;
    background-size:100%;

  }
`;
 